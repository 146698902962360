import * as dompack from 'dompack';
import $ from 'jquery';

var resizetime;
var resizetimeout = false;
var resizedelta = 300;

dompack.onDomReady(() => {

  if ($("#annonceform").length===1) {
    // form is on page
    var annoncedata;
    //console.log("has form");
    $.ajax({
      url: "/data.shtml",
      data: {
        action: "getAnnonceConfig"
      },
      success: function(data, statustext, jqXHR) {
        //console.log(data);
        if (data && data.item) {
          //console.log(data.item);
          if ($("#annonceform.create").length===1) {
            setAnnonceAmount(data.item);
          }
          annoncedata=data.item;
          $(document).on("keyup", "#annonceform .formfield.countchars textarea", function(e) {
            setAnnonceAmount(data.item);
          });
          $(document).on("click", ".magazines input:checkbox", function(e) {
            setAnnonceAmount(data.item);
          });

        }
      }
    });

    $('#imageField').change(function(){ 
      //console.log(annoncedata);
      var input = this;
      var url = $(this).val();
      var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
      if (input.files && input.files[0] && (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg"))
      { 
        var reader = new FileReader();
        
        reader.onload = function (e) {
          $('#imagepreview img').attr('src', e.target.result);
          $("#image__i_delete").parent(".filedeletelabel").removeClass("hidden");
          $("#image__i_delete").closest(".part2").find("small.error").addClass("hidden");
          $("#formfeedback").addClass("hidden");
          setAnnonceAmount(annoncedata, true);
        }
        reader.readAsDataURL(input.files[0]);
      }
      else
      {
        $('#imagepreview img').attr('src', '/resources/img//preview-no-image.png');
      }
    });

    $(document).on("change", "#image__i_delete", function(e) {
      //console.log(annoncedata);
      if (this.checked) {
        $("#imagepreview img").attr("src", "/resources/img/preview-no-image.png");
        $(this).parent(".filedeletelabel").addClass("hidden");
        $(this).closest(".part2").find("small.error").addClass("hidden");
        $("#formfeedback").addClass("hidden");
        setAnnonceAmount(annoncedata, false);
      }
    });


  }

});

function setAnnonceAmount(config, _hasimage) {
  if ($("#annonceform.create").length===1) {
    var maxnrofchars = config.maxnrofcharsdescription;
    var textarea = $("#annonceform .formfield.countchars textarea");
    var nrofcharstext = $(textarea).val().length;
    var nrofchars = nrofcharstext;
    if (nrofchars>maxnrofchars) {
      //$(textarea).val($(textarea).val().substring(0, maxnrofchars));
      //nrofchars = maxnrofchars;
    }
    $(textarea).parent().find(".counter").text( nrofchars + " / " + maxnrofchars + " tekens" );
    if ((nrofcharstext>0) && (nrofcharstext<=maxnrofchars)) {
      $(textarea).parent().find("small.error").addClass("hidden");
    }
    else {
      $(textarea).parent().find("small.error").text("Te lang, maximaal aantal tekens is " + maxnrofchars + ".");
      $(textarea).parent().find("small.error").removeClass("hidden");
    }
    var nrofmagazines = $(".magazines input:checkbox:checked").length;
    var textamount = calcTextAmount(nrofchars, config);
    if (nrofmagazines>1) {
      $("#annonceform .amount").text( getMoney(nrofmagazines * textamount, 2) + " (" + nrofmagazines + " x " + getMoney(textamount, 2) + ")" );
    }
    else {
      $("#annonceform .amount").text( getMoney(textamount, 2) );
    }
    //console.log($(".magazines input:checkbox:checked").length);
  }
  if ($("#annonceform.image").length===1) {
    if (_hasimage) {
      $("#annonceform .amount").text( getMoney(config.nrofmagazines * (config.textamount + config.amountperimage), 2) );
    }
    else {
      $("#annonceform .amount").text( getMoney(config.nrofmagazines * config.textamount, 2) );
    }
  }

}

function calcTextAmount(_nrofchars, _config)
{
  if (_nrofchars === 0) {
    return 0;
  }
  var result = _config.amountbase;
  var nrofcharsremaining = _nrofchars;
  if (_nrofchars <= _config.nrofcharsbase) {
    return result;
  }
  else {
    nrofcharsremaining = nrofcharsremaining - _config.nrofcharsbase;
    result = result + ( Math.ceil(nrofcharsremaining/_config.nrofcharsextra) * _config.amountextra);
  }
  return result;
}

function getMoney(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}); //.toFixed(decimals);
}
