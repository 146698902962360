import './splith1nav.scss';

import * as dompack from 'dompack';
import $ from 'jquery';

function ensureAnchor(node, pageidx)
{
  //preference order:
  let nodewithid = node.querySelector('a[name]') || node.querySelector('a[id]') || node.querySelector('[id]');
  if(nodewithid)
    return nodewithid ? nodewithid.name || nodewithid.id : null;

  node.id = 'page-' + pageidx;
  return node;
}

export default class SplitH1Nav
{
  constructor(node, options)
  {
    this.node = node;
    this.options = {...options};
    this.pagestarts = dompack.qSA('#contentwrapper-contents > h1');
    this.contentwrappercontents = dompack.qS('#contentwrapper-contents');
    this.firstcontentelement = null;
    if (this.contentwrappercontents) {
      this.firstcontentelement = this.contentwrappercontents.children[0];
      if ( (this.firstcontentelement) && (this.pagestarts.length>0) && (this.firstcontentelement !== this.pagestarts[0]) ) {
        this.pagestarts.unshift(this.firstcontentelement);
      }
    }
    //get or create an anchor for all the sections
    this.pageanchors = this.pagestarts.map((node,idx) => ensureAnchor(node, idx+1));

    this.addH1Nav();

    //update pagecontrols at init and whenever the hash changes
    this.updateForCurrentPage();
    addEventListener("hashchange", () => this.updateForCurrentPage());
  }

  getCurrentPageStart() //get idx of current page
  {
    let currenthash = (location.hash || "#").substr(1);
    let anchornode = document.querySelector(`[id="${CSS.escape(currenthash)}"],a[name="${CSS.escape(currenthash)}"]`); //find HTML5 and legacy anchors
    if(!anchornode)
      return 0;

    //find the #contentwrapper-contents childNode that holds us
    let mycontainer = anchornode;
    while(mycontainer.parentNode && mycontainer.parentNode.id != "contentwrapper-contents")
      mycontainer = mycontainer.parentNode;

    //find the pagestart containing us
    while(mycontainer && !this.pagestarts.includes(mycontainer))
      mycontainer = mycontainer.previousElementSibling;

    return mycontainer ? this.pagestarts.indexOf(mycontainer) : -1;
  }

  updateForCurrentPage()
  {
    let curpage = this.getCurrentPageStart();
    if(curpage === this.lastpage)
      return;

    this.lastpage = curpage;

    this.updateH1Nav();

    //regenerate nav controls
    let navnodes = [];
    if(curpage > 0)
      navnodes.push(<a href={"#" + this.pageanchors[curpage-1]} class="splith1nav__navlink js-splith1nav-prev show">&laquo; vorige</a>);
    if(this.pagestarts.length)
      navnodes.push(<a href={"#" + this.pageanchors[0]} class="splith1nav__navlink js-splith1nav-index show">…</a>);
    if(curpage < this.pagestarts.length - 1)
      navnodes.push(<a href={"#" + this.pageanchors[curpage+1]} class="splith1nav__navlink js-splith1nav-prev show">volgende &raquo;</a>);

    document.getElementById('splith1nav').replaceChildren(...navnodes);

    //update visibility of the page contents
    for(let pagenum = 0; pagenum < this.pagestarts.length; ++pagenum)
      for(let node = this.pagestarts[pagenum]; node && node != this.pagestarts[pagenum+1]; node=node.nextElementSibling)
        node.hidden = pagenum != curpage;

    if (this.options.onPageChange)
      this.options.onPageChange();
  }

  updateH1Nav()
  {
    if (!this.haspagesidebarright) {
      return;
    }

    this.$currentmenuitemfordoc.parent().find("ul .s-menu__link--selected").removeClass("s-menu__link--selected");
    let currenthash = (document.location.hash || "#").substr(1);
    let menuhash = currenthash;
    if ( (menuhash == "") && (this.firstcontentelementhash) ) {
      menuhash = this.firstcontentelementhash;
    }
    var menuitemselector = "aside .sidebar nav.s-menu a[href='#" + menuhash + "']";
    let $menuitem = $(menuitemselector);
    $menuitem.addClass("s-menu__link--selected");
    this.scrollToTop();
  }

  addH1Nav()
  {
    let self = this;
    this.haspagesidebarright = $("html").hasClass("page-sidebar-right");
    if (!this.haspagesidebarright) {
      return;
    }

    this.firstcontentelementhash = null;
    if ( (this.firstcontentelement.tagName == "H1") && (this.firstcontentelement.classList.contains("wh-heading1")) )
    {
      this.firstcontentelementhash = this.firstcontentelement.querySelector("a[name]").name || null;
    }

    var menuitemselector = "aside .sidebar nav.s-menu a[href='" + document.location.href.replace(document.location.hash,"").split('#')[0].split('?')[0] + "']";
    this.$currentmenuitemfordoc = $(menuitemselector);
    if (this.$currentmenuitemfordoc.length>0)
    {
      var addhtml = '<ul class="s-menu__list">';

      $('#contentwrapper-contents > h1.wh-heading1').each( function(_index, _element) {
        if (_element == self.firstcontentelement) {
          return true; // continue
        }

        let nodewithname = _element.querySelector('a[name]');
        if (nodewithname)
        {
          addhtml += '<li class="s-menu__listitem">';
          addhtml += '<a class="s-menu__link" href="#' + nodewithname.name + '">';
          addhtml += _element.innerText;
          addhtml += '</a>';
          addhtml += '</li>';
        }
      });

      addhtml += '</ul>';
      $(menuitemselector).parent().append(addhtml);
    }

  }

  scrollToTop() {
    $('html,body').animate({
      scrollTop: 0
    }, 500);
  }

  scrollToMainTop() {
    $('html,body').animate({
      scrollTop: $('main.contentwrapper__col--content').offset().top,
    }, 500);
  }

}
