
import './largeimageandtext.scss';

import * as dompack from 'dompack';
import $ from 'jquery';


dompack.onDomReady(() => {

});

