
import './foldableitems.scss';

import * as dompack from 'dompack';
import $ from 'jquery';


dompack.onDomReady(() => {

  $(document).on("click", ".widget--foldableitems__items > li > div.widget--foldableitems__item__header > a", function(_e) {
    _e.preventDefault();
    let $li = $(this).parent().parent();
    $(this).parent().parent().find(".widget--foldableitems__item__body").slideToggle("slow", function() { $li.toggleClass("widget--foldableitems__item__state--open"); });
  });

});

