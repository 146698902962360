import './photoslider.scss';

import * as dompack from 'dompack';

import $ from 'jquery';
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

class PhotoSlider {
  // node = .w-photoslider
  constructor(node) {
    const $container = $(node);
    const $swiper = $container.find('.swiper-container').get(0);

    const numSlides = $(node).find('.swiper-slide').length;
    if (numSlides <= 1) {
      return;
    }

    new Swiper($swiper, {
      loop: true,
      spaceBetween: 0,
      slidesPerView: 'auto',
      grabCursor: true,
      navigation: {
        prevEl: '.w-photoslider__navbutton--prev',
        nextEl: '.w-photoslider__navbutton--next',
      },
      on: {
        slideChangeTransitionStart: function() {
          $container.find('.w-photoslider__curpage').text(this.realIndex + 1);
        },
      },
    });
  }
}

dompack.register('.w-photoslider', node => new PhotoSlider(node) );
