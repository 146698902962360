import './menubar.scss';

import * as dompack from 'dompack';

import $ from 'jquery';

export default class MenuBar {
  // node is the container with class "menubar"
  constructor(node) {
    // cache elements
    this.node = node;
    this.elContainer = node.querySelector('.menubar__container');
    this.menuBottomPadding = 30;

    // set width for main menu items
    const numMainMenuItems = $('.menubar__mainmenu-item').length;
    //$('.menubar__mainmenu-item').css('width', `calc(100% / ${numMainMenuItems})`);

    // enable sub menu items
    $('.menubar__submenu').addClass('show');

    // measure heights
    this.navHeight = this.elContainer.querySelector('nav').getBoundingClientRect().height + this.menuBottomPadding;
    this.initialHeight = node.getBoundingClientRect().height;

    this.elContainer.addEventListener('mouseover', () => this.open());

    if (dompack.debugflags['menudebug']) {
      this.open();
    } else {
      this.node.addEventListener('mouseleave', () => this.close());
    }
  }

  // set the height of .menubar to the content height
  open() {
    this.node.style.height = `${this.navHeight}px`;
    this.node.classList.add('is-open');
  }

  // set the height of .menubar to 0
  close() {
    this.node.style.height = `${this.initialHeight}px`;
    this.node.classList.remove('is-open');
  }
}

dompack.register('.menubar', node => new MenuBar(node));
