import './sliderimages.scss';

import * as dompack from 'dompack';
import $ from 'jquery';
import Swiper, { Navigation,Pagination,Autoplay } from 'swiper';
Swiper.use([Navigation,Pagination,Autoplay]);

class WSliderImages {
  // node = .w-sliderimages
  constructor(node) {
    const $swiper = $(node).find('.swiper-container').get(0);
    const numSlides = $(node).find('.swiper-slide').length;
    if (numSlides <= 1) {
      return;
    }

    $(node).addClass('has-slides');

    new Swiper($swiper, {
      loop: true,
      spaceBetween: 0,
      slidesPerView: 'auto',
      grabCursor: true,
      autoplay: {
        delay: 6000,
      },
      navigation: {
        prevEl: '.js-sliderimages-prev',
        nextEl: '.js-sliderimages-next',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }
}

dompack.register('.w-sliderimages', node => new WSliderImages(node) );
