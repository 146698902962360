import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

import "./apporganization.scss";

function processMaps() {

  if ($(".osmap.auto").length>0) {
    $(".osmap.auto").each( function(_index) {
      var grouptoggle = $(this).closest(".group--toggle");
      if (grouptoggle.hasClass("group--closed")) {
        return true;
      }
      var datamap = $(this).data("map");
      console.log("datamap:", datamap);
      var mapid = $(this).attr("id");

      if (datamap.markers) {
        var markers = {}
        var projects = {}
        var centerlatlng = [52.0946547, 5.3456721];
        var map = L.map(mapid, { zoomSnap:0, zoomControl: false }).setView(centerlatlng, 7);

        L.control.zoom({ position:'bottomright'
                       }).addTo(map);

        var attributioncontrol = map.attributionControl;
        attributioncontrol.setPrefix(false);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        var rotaryIcon = L.icon({ iconUrl: '/resources/img/rotarypointer.png', iconSize: [30,30], iconAnchor: [15,30], popupAnchor: [0, -31]});

    //var markerGroup = L.layerGroup().addTo(map);

        var bounds = [];
        bounds.push([52.1242208,6.4486221]);
        bounds.push([52.3702157,4.8951678999999]);

        var markersGroup = L.layerGroup().addTo(map);

        for (var marker of datamap.markers) {
          console.log(marker);
          var newmarker = L.marker([marker.lat, marker.lng], {icon: rotaryIcon}).addTo(markersGroup);
          map.setView([marker.lat, marker.lng], 11);
        }
      }
    });

  } // .osmap.auto

}

dompack.onDomReady(() => {

  //console.log("apporganization js here");
  $(document).on("click", ".memberdata .organization__image-toggle", function(_e) {
    //console.log("click image");
    _e.preventDefault();
    $(this).parent().toggleClass("flex-row");
    $(this).parent().toggleClass("flex-column");
  });

  //processMaps();

  $(document).on("click", "#amapmeetinglocation", function(_e) {
    //console.log("click");
    _e.preventDefault();
    //var target = $("a[name=mapmeetinglocation]").offset().top - 52;
    var target = $(".organization_map h2").offset().top - 52;
    //console.log("target:", target);
    $('html,body').animate({
      scrollTop: target
    }, 1000);
  });

  $(document).on("click", ".group--toggle .group--header a", function(_e) {
    console.log("click group action");
    _e.preventDefault();
    var grouptoggle = $(this).closest(".group--toggle");
    grouptoggle.toggleClass("group--closed");
    grouptoggle.find(".actions .fa-minus").toggleClass("hidden");
    grouptoggle.find(".actions .fa-plus").toggleClass("hidden");
    //processMaps();
  });

  $(document).on("click", "html.pwa .organization .website", function(_e) {
    _e.preventDefault();
    var url = $(this).attr("href");
    //alert("window.open: " + url);
    window.open(url, "_blank");
  });

});

