import './fullvideo.scss';

import * as dompack from 'dompack';
import $ from 'jquery';

dompack.onDomReady(() => {
  $('.js-fullvideo-scrolldown').each(function(evt) {
    const $nextWidget = $(this).closest('.widget').nextAll('.widget').first();
    const $scroller = $(this).closest('.w-fullvideo__scrolldown');
    if ($nextWidget.length === 0) {
      $scroller.remove();
      return;
    }

    $scroller.show();
    $(this).click(function(evt) {
      evt.preventDefault();
      const scrollTop = $nextWidget.offset().top - parseInt($('body').css('padding-top'), 10) || 0;

      $('html, body').animate({
        scrollTop: scrollTop,
      }, 600);
    });
  });

});

//dompack.register('.w-fullvideo .c-video', node => new YouTubeVideoPlayer(node) );
