import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

import "./appperson.scss";

dompack.onDomReady(() => {

  //console.log("appperson js here");
  $(document).on("click", ".memberdata .person__photo-toggle", function(_e) {
    //console.log("click photo");
    _e.preventDefault();
    $(this).parent().toggleClass("flex-row");
    $(this).parent().toggleClass("flex-column");
  });

});

