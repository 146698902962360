import * as dompack from 'dompack';
import * as forms from '@mod-publisher/js/forms';
import UploadField from '@mod-publisher/js/forms/fields/upload';
import { SplitDateField, SplitTimeField } from '@mod-publisher/js/forms/fields/splitdatetime';
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
import RTDField from '@mod-publisher/js/forms/fields/rtd';
import $ from 'jquery';

//import * as googleRecaptcha from '@mod-publisher/js/captcha/google-recaptcha';

// Styling
import '@mod-publisher/js/forms/themes/neutral';

// Enable forms and our builtin validation
forms.setup({validate:true});

// Replaces upload fields with a nicer and edit-supporting version
dompack.register('.wh-form__upload', node => new UploadField(node));

// Replaces date fields with a split version
dompack.register('.wh-form__date', node => new SplitDateField(node));
dompack.register('.wh-form__time', node => new SplitTimeField(node));

// Enable the imgedit and/or rtd fields:
dompack.register('.wh-form__imgedit', node => new ImgEditField(node));
dompack.register('.wh-form__rtd', node => new RTDField(node));

// Enable the line below AND the googleRecaptcha import if you want to use this recaptcha. you'll also need to enable it in the site profile
//googleRecaptcha.setupGoogleRecaptcha();

// create an event to 'catch' errors and move the error message above the form
let errors = [];
dompack.register(".wh-form__fieldgroup", group => group.addEventListener("wh:form-displaymessage", evt => {
  if (!evt.detail || evt.detail.type !== 'error') {
    return;
  }

  /* evt.target = .wh-form__fieldgroup
     evt.detail.field = the DOM input node (can appear multiple times, for example in date or time fields)
     evt.detail.message = the DOM node containing the error message
     evt.detail.type = 'error' (or 'suggestion', etc)
  */

  const fieldId = evt.target.dataset.whFormGroupFor;

  if (!evt.detail.field) { // if there's no field, there's no error
    // remove from errors array
    errors = errors.filter(error => error.id != fieldId);
    // and from DOM
    $(`#form-errors li[data-id="${fieldId}"]`).remove();
    return;
  }

  let errorMessage = evt.detail.message.textContent;
  let $label = $(evt.target).find('.wh-form__label');
  if ($label.length == 0) { // label not found? then we problaby need the first wh-form__optionlabel
    $label = $(evt.target).find('.wh-form__optionlabel').first();
  }

  if ($label.length == 0) {
    console.error('Cannot find label', evt.detail);
    return;
  }

  // if error not seen yet, add it (some fielde like date and time have multiple events fired)
  const existingError = errors.find(error => error.id == fieldId);
  if (!existingError) {
    errors.push({ id: fieldId,
                  fieldTitle: $label.text(),
                  error: errorMessage,
                  target: evt.target,
                  idx: $(evt.target).index(),
                });

    //FIXME: can be cleaner than removing everything and adding the sorted array; however I'm pretty sure this way of showing errors will be removed soon anyway
    $('#form-errors').empty();
    for (const error of errors.sort((a, b) => a.idx - b.idx)) {
      $('#form-errors').append(`<li data-id="${error.id}">${error.fieldTitle}: ${error.error}`);
    }
  }
}));

// create a container for the errors
dompack.onDomReady(() => {
  $('.wh-form').prepend(`<ul id="form-errors"></ul>`);
});

// prevent jumping to fields (we have to copy https://my.rotary.org/en/contact, which also doesn't jump to fields)
document.addEventListener("dompack:scrollintoview", evt => {
  evt.preventDefault();
});
