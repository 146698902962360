import * as util from './utilities';

import $ from 'jquery';

export default class VideoPlayersController {

  constructor(_$videoplayerstocontrol) {

    this.videoplayers = new Array();
    this.nrofvimeovideos = 0;
    this.nrofyoutubevideos = 0;
    this.vimeoscripttagadded = false;
    this.youtubescripttagadded = false;
    this.vimeointervalid = -1;

    var self = this;

    _$videoplayerstocontrol.each( function(_index) {
      var $playerelement = $(this);
      var playerid = $(this).data("player-id");
      var videonetwork = $(this).data('video-network');
      var videoid = $(this).data('video-id');
      var elementwidth = $(this).width();
      var elementheight = $(this).height();
      var videoplayersettings = null;
      if (videonetwork === "youtube") {
        self.nrofyoutubevideos++;
        var onstatechange = function(_event) {
          if (_event.data == YT.PlayerState.PLAYING) {
            $playerelement.find('.js-video-screen').addClass('active');
            window.setTimeout(() => {
              $playerelement.find('.c-video__posterimage').addClass('hide');
            }, 150);
          }
        }
        videoplayersettings = getYoutubePlayerSettings(videoid, onstatechange)
      }
      else if (videonetwork === "vimeo") {
        self.nrofvimeovideos++;

        var setwidth = $(window).width();
        var setheight = $(window).height();

        if (setwidth/setheight > 16/9) {
          setheight = 0.5625 * setwidth;
        }
        else {
          setheight = 0.5625 * setwidth;
        }
        //var vimeourl = "https://player.vimeo.com/video/" + videoid + "?muted=1&autoplay=1&loop=1&api=1&player_id=" + playerid;
        videoplayersettings = getVimeoPlayerSettings(videoid, setwidth, setheight, playerid)
      }
      
      if (!self.youtubescripttagadded && (self.nrofyoutubevideos>0)) {
        // add youtube script tag
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';

        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        self.youtubescripttagadded = true;
        window.onYouTubePlayerAPIReady = function(){ self.onYoutubeApiReady(); };
      }

      if (!self.vimeoscripttagadded && (self.nrofvimeovideos>0)) {
        // add vimeo script tag
        let tag = document.createElement('script');
        tag.src = "https://player.vimeo.com/api/player.js";
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        self.vimeoscripttagadded = true;
        self.vimeointervalid = setInterval(self.waitForVimeo.bind(self), 500);
      }

      var arrayid = playerid.substring(7)-1; // id like "player-1", strip for number, number starts at 1, array at 0
      self.videoplayers[arrayid] = {
        isvimeo: videonetwork === "vimeo",
        isyoutube: videonetwork === "youtube",
        videoid: videoid,
        settings: videoplayersettings,
        initwidth: elementwidth,
        initheight: elementheight,
        $playerelement: $(this),
        player: null,
        playerid: playerid,
      };
    });
      
    $(window).on('resize', () => {
      this.onResize();
    });

    //window.addEventListener('scroll', () => this.onScroll(), util.supportsPassive() ? { passive: true } : false);
  }

  waitForVimeo() {
    if (typeof Vimeo !== 'undefined') {
      clearInterval(this.vimeointervalid);
      this.onVimeoApiReady(); 
    }
  }

  onVimeoApiReady() {
    var self = this;
    this.videoplayers.forEach( function(_element, _index) {
      if (_element.isvimeo) {
        self.videoplayers[_index].player = new Vimeo.Player(_element.playerid, _element.settings);
        self.videoplayers[_index].player.on("play", function() {
        });
        self.videoplayers[_index].player.on("playing", function() {
          _element.$playerelement.find('.js-video-screen').addClass('active');
          window.setTimeout(() => {
            _element.$playerelement.find('.c-video__posterimage').addClass('hide');
          }, 150);
        });
        self.videoplayers[_index].player.on("loaded", function() {
/*
          // add param to iframe src to support multiple vimeo videos on page
          var iframe = _element.$playerelement.find("iframe");
          var iframesrc = iframe.attr("src");
          if (iframesrc && iframesrc.match(/player_id/g) === null) {
            //append a unique id query string.
            var param = $.param({"api": 1, "player_id": _element.playerid});
            iframe.attr("src", iframesrc + "&" + param);
          }
*/
        });
      }
    });
    this.onResize();
  }

  onYoutubeApiReady() {
    var self = this;
    this.videoplayers.forEach( function(_element, _index) {
      if (_element.isyoutube) {
        self.videoplayers[_index].player = new YT.Player(_element.playerid, _element.settings);
/*
        _element.$playerelement.find('.js-video-screen').addClass('active');
        window.setTimeout(() => {
          _element.$playerelement.find('.c-video__posterimage').addClass('hide');
        }, 150);
*/
      }
    });
    this.onResize(); // fixme: jannes: first time only resize specific network instead of all ?
  }

/*
  onPlayerReady() {
    //this.player.loadVideoById({ videoId: this.videoID, suggestedQuality: 'hd720' });
    //this.player.mute();
    //this.player.playVideo();
  }

  onPlayerStateChange(e) {
    if (e.data === 1) {
      this.firstPlayStarted = true;

      this.$node.find('.js-video-screen').addClass('active');
      window.setTimeout(() => {
        this.$node.find('.c-video__posterimage').addClass('hide');
      }, 150);
    }
  }
*/

  onResize() {
    var self = this;
    this.videoplayers.forEach( function(_element, _index) {
      let width = $(window).width();
      let height = $(window).height();
      if (width/height > 16/9) {
        if (_element.isyoutube) {
          self.videoplayers[_index].player.setSize(width, width/16*9);
        }
        else if (_element.isvimeo) {
          self.videoplayers[_index].$playerelement.find("iframe").css({width: width, height: height});
        }
        _element.$playerelement.find('.c-video__screen').css({left: '0px'});
      } 
      else {
        if (_element.isyoutube) {
          self.videoplayers[_index].player.setSize(height/9*16, height);
        }
        else if (_element.isvimeo) {
          self.videoplayers[_index].$playerelement.find("iframe").css({width: width, height: height});
        }
        _element.$playerelement.find('.c-video__screen').css({ left: -(_element.$playerelement.find('.c-video__screen').outerWidth() - width) / 2 });
      }
    });
  }

/*
  onScroll() {
    if (!this.player || this.firstPlayStarted !== true) {
      return;
    }

    let videoVisible = util.isInViewport(this.$node.get(0));
    if (videoVisible) {
      if (this.player.getPlayerState() === 2) { // is paused
        this.player.playVideo();
      }
    } else if (this.player.getPlayerState() === 1) { // is playing
      this.player.pauseVideo();
    }
  }
*/
}

function getVimeoPlayerSettings(_videoid, _setwidth, _setheight, _playerid)
{
  return {
    muted: true,
    autoplay: true, 
    id: _videoid,
    //url: _url,
    loop: true,
    width: _setwidth,
    height: _setheight,
    player_id: _playerid,
    api: 1,
  };
}

function getYoutubePlayerSettings(_videoid, _onstatechange)
{
  return {
    events: {
      //onReady: this.onPlayerReady.bind(this),
      //onStateChange: this.onPlayerStateChange.bind(this),
      //onReady: function() { console.log("onready"); },
      //onStateChange: function() { console.log("onstatechange"); $(this).find('.js-video-screen').addClass('active');},
      onStateChange: _onstatechange
    },
    playerVars: {
      mute: 1,
      autoplay: 1,
      autohide: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
      controls: 0,
      loop: 1,
      disablekb: 1,
      enablejsapi: 0,
      iv_load_policy: 3,
      videoid: _videoid,
      playlist: [ _videoid ],
    },
  };
}

