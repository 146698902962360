import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

// Load the core build.
//var _ = require('lodash/core');
import _ from 'lodash';

import "./appactivities.scss";

var initformdata = {};

dompack.onDomReady(() => {

  //console.log("appactivities js here");

  initformdata = getFormData();

  var qvar = _getQueryVar("ed");
  
  var actioniconshtml =  "";

  if ($("html").hasClass("pwa-activities")) {
    if ($("html.pwa-activity.can_updateattendance").length > 0) {
      actioniconshtml +=  '<a href="" title="" class="save">opslaan</a>';
    }
    if ($("html").hasClass("writeaccess")) {
      if (qvar.length>0) {
        var appdata = $("div.appactivitycontent").data("app");
        if (appdata) {
          actioniconshtml +=  '<a href="' + appdata.edit_url + '" title="" class="edit"><i class="fas fa-edit"></i></a>';
        }
      }
      else {
        //actioniconshtml =  '<a href="" title="" class="add"><i class="fas fa-plus"></i></a>';
      }
    }

    setTimeout(function(){
      // fixme: jannes: actions to be added later
      $(".nav-pwa-header span.right").html(actioniconshtml);
    }, 200);

  }

  $(document).on("click", "html.pwa-activity a.save", function(_e) {
    _e.preventDefault();
    $("#formattendance").trigger("submit");
  });

  $(document).on("click", "html.pwa-activities a.add", function(_e) {
    _e.preventDefault();
    alert("Hier komt functionaliteit voor toevoegen activiteit.");
  });


//  $(document).on("click", "html.pwa-activities a.edit", function(_e) {
//    //_e.preventDefault();
//    //alert("Hier komt functionaliteit voor bewerken activiteit.");
//  });

  $(".memberdata.activity textarea").each(function () {
    //console.log("textarea set height");
    this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
  }).on("input", function () {
    //console.log("textarea input");
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
  });

  var nrofrecords = $("div.records .record").length;
  $(".button.deleterecord").attr("disabled", nrofrecords==0);

  $(document).on("click", ".button.addrecord", function(e) {
    var nrofguests = getFormfieldNrOfRecords($(this).closest("div.formfield"));
    var records = $(this).closest("div.part2").find("div.records");
    //var nrofguests = parseInt($("#nrofguestsField").val());
    //var maxnrofguests = parseInt($("#maxnrofguestsField").val());
    var maxnrofguests = parseInt(records.find(".maxnrofitems").text());
    if (nrofguests < maxnrofguests) {
      var newnumber = nrofguests +1;
      var recordhtml = '<div class="record">';
      recordhtml += records.find(".template").html();
      recordhtml += '</div>';
      recordhtml = recordhtml.replace(/recordnumber/g, (newnumber-1).toString());
      records.append(recordhtml);
      $("#nrofguestsField").val(newnumber);
      $(".button.addrecord").attr("disabled", newnumber>=maxnrofguests);
      $(".button.deleterecord").attr("disabled", false);
      //refreshPaymentAmount();
    }
    refreshSaveStatus();
  });

  $(document).on("click", ".button.deleterecord", function(e) {
    var nrofguests = getFormfieldNrOfRecords($(this).closest("div.formfield"));
    var records = $(this).closest("div.part2").find("div.records");
    //var maxnrofguests = parseInt($("#maxnrofguestsField").val());
    var maxnrofguests = parseInt(records.find(".maxnrofitems").text());
    //var nrofguests = parseInt($("#nrofguestsField").val());
    var newnumber = nrofguests -1;
    if (newnumber<0) {
      newnumber = 0;
    }
    $(this).closest("div.part2").find("div.records .record").last().remove();
    $("#nrofguestsField").val(newnumber);
    $(".button.deleterecord").attr("disabled", newnumber==0);
    $(".button.addrecord").attr("disabled", newnumber>=maxnrofguests);
    //refreshPaymentAmount();
    refreshSaveStatus();
  });

  $(document).on("change", 'html.pwa-activities input[type="radio"][name="attendant"]', function(e) {
    console.log("radio change");
    //$('input[name="btnSave"]').removeClass("white-skyblue").addClass("skyblue-white");
    //$(".nav-pwa-header a.save").toggleClass("changed");
    refreshSaveStatus();
  });

  $(document).on("input", 'html.pwa-activities textarea', function(e) {
    console.log("textarea change");
    //$('input[name="btnSave"]').removeClass("white-skyblue").addClass("skyblue-white");
    refreshSaveStatus();
  });

  $(document).on("input", 'html.pwa-activities #formattendance input[name="input1"],html.pwa-activities #formattendance input[name="input2"],html.pwa-activities #formattendance input[name="input3"],html.pwa-activities #formattendance input[name^="guests["]', function(e) {
    refreshSaveStatus();
  });
/*
  var nrofguestrecords = getFormfieldNrOfRecords($('#formattendance .formfield.recordarray'));
console.log("nrofguestrecords:", nrofguestrecords);
  for(var i=0; i<=nrofguestrecords; i=i+1)
  {
    $(document).on("input", 'html.pwa-activities #formattendance input[name="guests"]', function(e) {
      refreshSaveStatus();
    });
  }
*/

}); // onDomReady

function _getQueryVar(_varname)
{
  var querystring = window.location.search.substring(1);
  var queryvars = querystring.split("&");
  for(var i=0; i<queryvars.length; i++) {
    var queryvar = queryvars[i].split("=");
    if (queryvar[0] == _varname) {
      return queryvar[1];
    }
  }
  return "";
}

function getFormfieldNrOfRecords(_formfield)
{
  return _formfield.find(".records .record").length;
}

function refreshSaveStatus()
{
  var formdata = getFormData();
  //console.log("formdata equal:", _.isEqual(initformdata, formdata), initformdata, formdata);
  if (_.isEqual(initformdata, formdata))
  {
    $(".nav-pwa-header a.save").removeClass("changed");
    $('input[name="btnSave"]').removeClass("skyblue-white").addClass("white-skyblue");
  }
  else {
    $(".nav-pwa-header a.save").addClass("changed");
    $('input[name="btnSave"]').removeClass("white-skyblue").addClass("skyblue-white");
  }
}

function getFormData()
{
  var result = {};
  result.attendant = $('#formattendance input[type="radio"][name="attendant"]:checked').val();
  result.note = $('#formattendance textarea[name="note"]').val();
  result.input1 = $('#formattendance input[name="input1"]').val();
  result.input2 = $('#formattendance input[name="input2"]').val();
  result.input3 = $('#formattendance input[name="input3"]').val();
  result.guests = [];

  var nrofguestrecords = getFormfieldNrOfRecords($('#formattendance .formfield.recordarray'));
  for(var i=0; i<=nrofguestrecords; i=i+1)
  {
    var namevalue = $('#formattendance input[name="guests[' + i + '][name]"').val();
    var emailvalue = $('#formattendance input[name="guests[' + i + '][email]"').val();
    if (typeof(namevalue) !== 'undefined') {
      result.guests.push({ 'name': namevalue, 'email': emailvalue });
    }
  }

  return result;
}

