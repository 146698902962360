import './mobilemenu.scss';

import * as dompack from 'dompack';
import * as util from '../../shared/js/utilities';

import $ from 'jquery';

dompack.onDomReady(() => {
  $('.mobilemenu__navsublist').each(function(idx, el) {
    $(this).addClass('closed');
  });

  // add open/close submenu click event
  $('.mobilemenu__navlink').click(function(evt) {
    if ($(this).hasClass('mobilemenu__navlink--nosubitems'))
      return; // let through

    evt.preventDefault();

    const $navListItem = $(this).closest('.mobilemenu__navlistitem');
    const currentIsAlreadyOpen = $navListItem.hasClass('open');

    // close all menus
    $('.mobilemenu__navlistitem').removeClass('open').attr('aria-expanded', 'false');
    $('.mobilemenu__navsublist').addClass('closed');

    if (currentIsAlreadyOpen)
      return;

    // open the correct one
    $navListItem.toggleClass('open').attr('aria-expanded', function(index, attr){ if (attr == 'true') return 'false'; return 'true'; });
    $navListItem.find('.mobilemenu__navsublist').toggleClass('closed');
  });

  // setup toggle mobile menu click event
  $('.js-toggle-mobile-menu').click(function(evt) {
    evt.preventDefault();

    const openingMenu = !$('html').hasClass('mobile-menu-open');
    $('html').toggleClass('mobile-menu-open');

    if (openingMenu) {
      $('html').removeClass('mobile-search-open');
      $('body').css({ position: 'fixed', top: `-${util.getScrollY()}px`, width: '100vw' });
    } else {
      closeMenu();
    }
  });

  $('.mobilemenu').addClass('initialized');
});

export function closeMenu() {
  const scrollY = parseInt($('body').css('top') || 0);

  if (scrollY) {
    $('body').css({ position: '', top: 0});
    window.scrollTo(0, -1 * parseInt(scrollY || '0'));
  } else if (scrollY === 0) {
    $('body').css({ position: '', top: 0});
  }

  $('html').removeClass('mobile-menu-open');
}
