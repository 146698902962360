import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

import * as rpc from '../../data.rpc.json';

import "./mystream.scss";

function showMenuContainer()
{
  //console.log("showMenuContainer");
  //const scrollY = document.body.style.top;
  const scrollY = window.scrollY;
  //console.log("show scrollY", scrollY);
  document.body.style.position = 'fixed';
  //document.body.style.overflow = 'hidden';
  document.body.style.top = `-${scrollY}px`;
  //console.log("body top", document.body.style);
  document.body.style.width = '100%';
  $("html.mystream.pwa .pwa-menu-container").show().animate({top:0}, 200, function() {});
}

function hideMenuContainer()
{
  //console.log("hideMenuContainer");
  const scrollY = document.body.style.top;
  //console.log("hide scrollY", scrollY);
  //const scrollY = $("html").scrollTop();
  document.body.style.position = '';
  document.body.style.top = '';
  //document.body.style.overflow = 'auto';
  document.body.style.width = 'auto';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  $("html.mystream.pwa .pwa-menu-container").animate({top:"100vh"}, 300, function() {
    $("html.mystream.pwa .pwa-menu-container").hide();
  });
}

function showDialogContainer(_dialogselector)
{
  //console.log("showDialogContainer");
  const scrollY = window.scrollY;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
  document.body.style.width = '100%';
  $(_dialogselector).show().animate({top:0}, 200, function() {});
}

function hideDialogContainer(_dialogselector)
{
  //console.log("hideDialogContainer");
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = 'auto';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  $(_dialogselector).animate({top:"100vh"}, 300, function() {
    $(_dialogselector).hide();
  });
}

dompack.onDomReady(() => {

  if ($("html").hasClass("mystream")) {
    //console.log("on page mystream");

    var actioniconshtml =  '<a href="" title="" class="show-dialog-stream-filter"><i class="fas fa-filter"></i></a><a href="" title="" class="home-menu"><i class="fas fa-bars"></i></a>';
    var actionbarhtml = '<div class="actionbar"><p class="textalign__right"><a href="" title="" class="show-dialog-stream-filter"><i class="fas fa-filter"></i> filter</a></div>';

    setTimeout(function(){
      //console.log("set", actioniconshtml);
      $(".nav-pwa-header span.right").html(actioniconshtml);
      $("html.mystream #contentwrapper-contents > .widgetblock").after(actionbarhtml);
    }, 200);


    $(document).on("click", "html.mystream a.show-dialog-stream-filter", function(_e) {
      //console.log("click for filter dialog");
      _e.preventDefault();
      showDialogContainer("html.mystream .stream-filter.dialog-container");
    });

    $(document).on("click", "html.mystream .dialog-body", function(_e) {
      _e.stopPropagation();
      //_e.preventDefault();
      console.log("click body");
    });

    $(document).on("click", "html.mystream .dialog-container-inner", function(_e) {
      _e.preventDefault();
      _e.stopPropagation();
      //console.log("click inner");
      //console.log(_e.target.classList);
      //if (_e.target.classList.contains("dialog-container-inner")) {
      //  console.log("cancel click inner");
      //}
    });

    $(document).on("click", "html.mystream .dialog-container, .dialog-container-inner a.close", function(_e) {
      _e.stopPropagation();
      _e.preventDefault();
      //console.log("click to close dialog");
      //if (_e.target.classList.contains("dialog-container")) {
      //  console.log("has class");
        hideDialogContainer("html.mystream .stream-filter.dialog-container");
      //}
    });

    $(document).on("click", "html.mystream .dialog-container .dialog-footer a.save", function(_e) {
      _e.stopPropagation();
      _e.preventDefault();
      //console.log("click to save dialog");
      var data = {
        showactivities: $('html.mystream .dialog-container .dialog-body #chkactivities').is(":checked")
      , showcountry: $('html.mystream .dialog-container .dialog-body #chkcountry').is(":checked")
      , showdistrict: $('html.mystream .dialog-container .dialog-body #chkdistrict').is(":checked")
      , showclub: $('html.mystream .dialog-container .dialog-body #chkclub').is(":checked")
      , showmembersonly: $('html.mystream .dialog-container .dialog-body #chkmembersonly').is(":checked")
      }
      //console.log("data:", data);
      let setmystreamfilter = _setMyStreamFilter(data); 
      //console.log("redirect:", setmystreamfilter);
      //window.location.reload();
    });

    $(document).on("click", "html.mystream.pwa .nav-pwa-header a.home-menu", function(_e) {
      _e.preventDefault();
      if ($("html.mystream.pwa .pwa-menu-container").length === 0) {
        var menuhtml = '<div class="pwa-menu-container">';
        menuhtml = menuhtml + '<div class="pwa-menu-container-inner">';
        menuhtml = menuhtml + '<div class="flex-items pwa-menu-header"><h2>Maak een keuze</h2><span><a href="#close" title="" class="close"><i class="fas fa-times"></i></a></span></div>';
        menuhtml = menuhtml + '<ul class="pwa-menu">';
        menuhtml = menuhtml + '<li class="menuitem"><a href="/leden/app/lidmaatschapskaart/" title=""><i class="fas fa-id-card"></i> Mijn lidmaatschapkaart</a></li>';
        menuhtml = menuhtml + '<li class="menuitem"><a href="/leden/app/anniversaries/" title=""><i class="fas fa-birthday-cake"></i> Verjaardagen (club)</a></li>';
        //menuhtml = menuhtml + '<li class="menuitem"><a href="/leden/profiel.shtml" title=""><i class="fas fa-id-card"></i> Mijn profiel</a></li>';
        menuhtml = menuhtml + '<li class="menuitem"><a href="/access/?action=logout" title="" class="logout"><i class="fas fa-sign-out-alt"></i> Uitloggen</a></li>';
        menuhtml = menuhtml + '</ul>';
        //menuhtml = menuhtml + '<p class="pwa-menu-footer"><img class="thumb" src="/resources/img/rotary-logo-wheel.svg" alt="" /> Rotary PWA version: 1.0 - 01-09-2022</p>';
        menuhtml = menuhtml + '</div>';
        menuhtml = menuhtml + '</div>';
        $("body").append(menuhtml);
      }
      showMenuContainer();
    });

    $(document).on("click", "html.mystream.pwa .pwa-menu-container a.close", function(_e) {
      _e.stopPropagation();
      _e.preventDefault();
      hideMenuContainer();
    });

    $(document).on("click", "html.mystream.pwa .pwa-menu-container", function(_e) {
      hideMenuContainer();
    });

    $(document).on("click", "html.mystream.pwa .pwa-menu-container-inner", function(_e) {
      _e.stopPropagation();
      //_e.preventDefault();
    });

    $(document).on("click", "html.mystream.pwa .pwa-menu-container-inner ul li a", function(_e) {
      //console.log("a click");
    });

    //$(document).on("click", "html.mystream.pwa .pwa-menu-container-inner ul li a", function(_e) {
    //});

    $(document).on("click", "html.mystream.pwa .pwa-menu-container a.logout", function(_e) {
      _e.stopPropagation();
    });

    var widgetAttendance = new function() {
      this.meetingid = 0;
      this.widget = $(".widgetblock.attendance");
      this.refreshList = function () {
        //console.log("widgetAttendance refreshList");
        this.meetingid = 0;
        page.showLoader();
        $.ajax({
          url: "/data.shtml?action=getClubMeetings",
          type: "GET",
          dataType: 'json',
          cache: false,
          contentType: false,
          processData: false,
          complete: function() {
            //thisform.removeClass('is-uploading');
          },
          success: function(_data) {
            setTimeout(function() {
              page.hideLoader();
            }, 100);
            if (_data.ok)
            {
              if (_data.htmldata)
              {
                widgetAttendance.widget.find("ul.clubmeetings").html(_data.htmldata);
              }
              else {
                widgetAttendance.widget.find("ul.clubmeetings").html('<li class="message">Er zijn op het moment geen toekomstige clubmeetings beschikbaar.</li>');
              }
            }
          }
        });
      };
      this.postData = function () {
        console.log("postdata");
      };

      $(document).on("submit", ".widgetblock.attendance form", function(e) {
        e.preventDefault();
        widgetAttendance.widget.find(".formfeedback").addClass("nodisplay");
        widgetAttendance.widget.find(".formfeedback p").html("");
        var formData = new FormData();
        formData.append('id', widgetAttendance.meetingid);
        formData.append('note', widgetAttendance.widget.find("form .formfield #noteField").val());
        formData.append('attendant', widgetAttendance.widget.find("form .formfield .attendant").prop("checked"));
        $.ajax({
          url: "/data.shtml?action=updateClubMeetingAttendance",
          type: "POST",
          data: formData,
          dataType: 'json',
          cache: false,
          contentType: false,
          processData: false,
          complete: function() {
            //thisform.removeClass('is-uploading');
          },
          success: function(_data) {
            setTimeout(function() {
              page.hideLoader();
            }, 100);
            if (_data.ok)
            {
              widgetAttendance.widget.find(".clubmeetings").html("");
              widgetAttendance.widget.find(".clubmeeting").addClass("nodisplay");
              widgetAttendance.widget.find(".clubmeetings").removeClass("nodisplay");
              widgetAttendance.refreshList();
            }
            else {
              widgetAttendance.widget.find(".formfeedback").removeClass("nodisplay");
              if (_data.errors) {
                var errorhtml = "";
                for (i in _data.errors) {
                  errorhtml = errorhtml + _data.errors[i] + "<br />";
                }
                widgetAttendance.widget.find(".formfeedback p").html(errorhtml);
              }
              else {
                widgetAttendance.widget.find(".formfeedback p").html("2; Er is iets fout gegaan bij het opslaan. Probeer het (later) opnieuw.");
              }
            }
          }
        });
      });

     $(document).on("click", ".widgetblock.attendance .clubmeetings li", function(e) {
        e.preventDefault();
        page.showLoader();
        $('html,body').animate({
          scrollTop: widgetAttendance.widget.offset().top
        }, 500);
          
        var clubmeetingid = $(this).data("id");
        widgetAttendance.meetingid = clubmeetingid;
        $(this).closest("ul").addClass("nodisplay");
        widgetAttendance.widget.find(".clubmeeting").removeClass("nodisplay");
        $.ajax({
          url: "/data.shtml?action=getClubMeeting&id=" + clubmeetingid,
          type: "GET",
          dataType: 'json',
          cache: false,
          contentType: false,
          processData: false,
          complete: function() {
            //thisform.removeClass('is-uploading');
          },
          success: function(_data) {
            setTimeout(function() {
              page.hideLoader();
            }, 100);
            if (_data.ok)
            {
              if (_data.htmldata) 
              {
                widgetAttendance.widget.find(".clubmeeting").html(_data.htmldata);
              }
            }
          }
        });
        widgetAttendance.widget.on("click", ".back", function(e) {
          e.preventDefault();
          widgetAttendance.widget.find(".clubmeeting").addClass("nodisplay");
          widgetAttendance.widget.find(".clubmeetings").removeClass("nodisplay");
        });

      });
    }; // widgetAttendance function

    $(document).on("click", "html.mystream aside .widgetblock.content .title", function(e) {
      //console.log("html.mystream widgetblock title click");
      if ($(this).closest(".widgetblock").hasClass("attendance")) {
        if ($(this).closest(".widgetblock").find("span.close").hasClass("nodisplay")) {
          widgetAttendance.refreshList();
        }
      }
      //$(this).closest(".widgetblock").find(".content").slideToggle("slow");
      //$(this).closest(".widgetblock").find("span.open").toggleClass("nodisplay");
      //$(this).closest(".widgetblock").find("span.close").toggleClass("nodisplay");
    });

  } // hasClass("mystream")

});


async function _setMyStreamFilter(_data) {
  let setstreamfilter = await rpc.setMyStreamFilter(_data) || {};
  //console.log("setstreamfilter: ", setstreamfilter);
  if (setstreamfilter && setstreamfilter.ok)
  {
    //console.log("streamfilter set ok");
    // reload current page to apply new filter
    hideDialogContainer("html.mystream .stream-filter.dialog-container");
    setTimeout(function() {
      //console.log("redirect here");
      window.location.reload();
    }, 100);
  }
}
