/* eslint-disable max-len */
/* global google */

export function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

export function isInViewport(elem) {
  let bounding = elem.getBoundingClientRect();

  let isVisible = bounding.top < document.documentElement.clientHeight
                  && -1 * bounding.top <= bounding.height;

  return isVisible;
}

export function supportsPassive() {
  // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
  // Test via a getter in the options object to see if the passive property is accessed
  let returnSupportsPassive = false;

  try {
    let opts = Object.defineProperty({}, 'passive', {
      get: () => {
        returnSupportsPassive = true;
      },
    });
    window.addEventListener('test', null, opts);
  } catch (e) {
    console.error(e);
  }

  return returnSupportsPassive;
}

export function noFullVideo() {
  return window.matchMedia('only screen and (max-width: 600px)').matches;
}

export function toRadians(degrees) {
  return degrees * Math.PI / 180;
}

export function toDegrees(radians) {
  return radians * 180 / Math.PI;
}

export function moveLatLng(latlng, direction, distance, radius = 6378137) {
  let lat1 = toRadians(latlng.lat());
  let directionRad = toRadians(direction);

  /*
  var φ2 = Math.asin( Math.sin(φ1)*Math.cos(d/R) + Math.cos(φ1)*Math.sin(d/R)*Math.cos(brng) );
  var λ2 = λ1 + Math.atan2(Math.sin(brng)*Math.sin(d/R)*Math.cos(φ1), Math.cos(d/R)-Math.sin(φ1)*Math.sin(φ2));
  */
  let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance / radius) + Math.cos(lat1) * Math.sin(distance / radius) * Math.cos(directionRad));
  let lng2 = Math.atan2(Math.sin(directionRad) * Math.sin(distance / radius) * Math.cos(lat1), Math.cos(distance / radius) - Math.sin(lat1) * Math.sin(lat2));

  lat2 = toDegrees(lat2);
  // Normalize into range -180 .. 180
  lng2 = latlng.lng() + toDegrees(lng2) + 540;
  while (lng2 > 360) {
    lng2 -= 360;
  }
  lng2 -= 180;

  return new google.maps.LatLng(lat2, lng2);
}
