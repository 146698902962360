/* global lightGallery */

import './photoalbum.scss';

import * as dompack from 'dompack';
import $ from 'jquery';

require('../../web/lightgallery/js/lightgallery.min.js');
require('../../web/lightgallery/js/lg-video.min.js');
require('../../web/lightgallery/js/lg-fullscreen.min.js');
require('../../web/lightgallery/js/lg-zoom.min.js');
require('../../web/lightgallery/js/lg-thumbnail.min.js');

dompack.onDomReady(() => {
  $('.w-photoalbum').each(function() {
    let options = {
      thumbnail: true,
      download: false,
      preload: 2,
      startClass: '',
      selector: '.w-photoalbum__photo',
    };

    lightGallery($(this).get(0), options);
  });

  $('.o-gallery').each(function() {
    let options = {
      thumbnail: true,
      download: false,
      preload: 2,
      startClass: '',
      selector: '.o-gallery__item',
    };

    lightGallery($(this).get(0), options);
  });
});
