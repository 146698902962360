import * as dompack from 'dompack';
import $ from 'jquery';

var resizetime;
var resizetimeout = false;
var resizedelta = 300;

dompack.onDomReady(() => {
  if ($("#projectsmap").length===1) {
    //console.log("map");

    setTimeout( function() {
      $("#projects").addClass("redrawgrid");
      redrawProjectsGrid();
    }, 200);

    $(window).resize( function()
    {
      if ($("#projectsmap").length===1) {
        resizetime = new Date();
        if (resizetimeout === false) {
          resizetimeout = true;
          //$("#projects .item").addClass("notransition");
          setTimeout(resizeEndProjectsGrid(), resizedelta);
          $("#projects .item")[0].offsetHeight;
          //$("#projects .item").removeClass("notransition");
        }
      }
    });

    var markers = {}
    var projects = {}
    var centerlatlng = [52.0946547, 5.3456721];
    var map = L.map('projectsmap', { zoomSnap:0, zoomControl: false }).setView(centerlatlng, 7);

    L.control.zoom({ position:'bottomright'
                   }).addTo(map);

    var attributioncontrol = map.attributionControl;
    attributioncontrol.setPrefix(false);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    var rotaryIcon = L.icon({ iconUrl: '/resources/img/rotarypointer.png', iconSize: [30,30], iconAnchor: [15,30], popupAnchor: [0, -31]});

    //var markerGroup = L.layerGroup().addTo(map);

    var bounds = [];
    bounds.push([52.1242208,6.4486221]);
    bounds.push([52.3702157,4.8951678999999]);

    var markersGroup = L.layerGroup().addTo(map);

    $.ajax({
      url: "/data.shtml",
      data: {
        action: "getHelptProjects"
      },
      success: function(data, statustext, jqXHR) {
        //console.log(data);
        if (data && data.items) {
          //console.log(data.items);
          $.each(data.items, function(index, item) {
            //console.log(item);
            if (item.lat.length>0) {
              var marker = L.marker([item.lat, item.lng], {icon: rotaryIcon}).addTo(markersGroup);
              var projectlink = "";
              if (item.projectlink!="") {
                projectlink = '<p><a href="' + item.projectlink + '" title="Ga naar projectpagina">Lees meer</a></p>';
              }
              marker.bindPopup('<div class="popup"><h3>' + item.wrd_title + '</h3><p>' + item.introtext + '</p>' + projectlink + '</div>', { direction:top });
              projects[ "p" + item.project_id ] = { 'item' : item, 'marker' : marker };
            }
          });
        }
      }
    });

    $(document).on("click", "#projects .item a.showmap", function(e) {
      e.preventDefault();
      var projectid = $(this).closest(".item").attr("id");
      var projectstop = $("#projectsmap").offset().top;
      if ($(".topbar").css("position") === "fixed") {
        //console.log("add to top");
        projectstop = projectstop - $(".topbar").height();
      }
      projectstop = projectstop - 10;
      $('html,body').animate({
        scrollTop: projectstop
      }, 1000);
      map.setView([projects[projectid].item.lat, projects[projectid].item.lng], 10);
      projects[projectid].marker.openPopup();
      $("#rezoommap").removeClass("nodisplay");
    });

    $(document).on("click", "#projectsintromap .widgetblock.link.highlights a", function(e) {
      e.preventDefault();
      $("#projectsfilter .filterhelptcategories li#cat-highlight").trigger("click");
    });

    $(document).on("click", "#projectsfilter .filterhelptcategories li", function(e) {
      e.preventDefault();
      var category = $(this).attr("id");
      $("#projectsfilter .filterhelptcategories li").removeClass("active");
      $(this).addClass("active");
      map.setView(centerlatlng, 7);
      $("#rezoommap").addClass("nodisplay");
      var projectstop = $("#projectsmap").offset().top;
      $('html,body').animate({
        scrollTop: projectstop
      }, 1000);
      //console.log(category);
      $.ajax({
        url: "/data.shtml",
        data: {
          action: "getHelptProjects"
        , category: category
        },
        success: function(data, statustext, jqXHR) {
          //console.log(data);
          if (data && data.items) {
            markersGroup.clearLayers();
            $("#projects .item").addClass("hidden");
            //alert("here");
            //console.log(data.items);
            $.each(data.items, function(index, item) {
              //console.log(item);
              $("#projects #p" + item.project_id).removeClass("hidden");
              if (item.lat.length>0) {
                //var marker = L.marker([item.lat, item.lng], {icon: rotaryIcon}).addTo(map);
                var marker = L.marker([item.lat, item.lng], {icon: rotaryIcon}).addTo(markersGroup);
                var projectlink = "";
                if (item.projectlink!="") {
                  projectlink = '<p><a href="' + item.projectlink + '" title="Ga naar projectpagina">Lees meer</a></p>';
                }
                marker.bindPopup('<div class="popup"><h3>' + item.wrd_title + '</h3><p>' + item.introtext + '</p>' + projectlink + '</div>', { direction:top });
                projects[ "p" + item.project_id ].marker = marker;
              }
            });
            redrawProjectsGrid();
          }
        }
      });
    });
  }

});

function resizeEndProjectsGrid()
{   
  //console.log("resizeEndProjectsGrid");
  if (new Date() - resizetime < resizedelta) {
    setTimeout(resizeEndProjectsGrid, resizedelta);
  } else {
    $("#projects").addClass("redrawgrid");
    redrawProjectsGrid();
  } 
} 

function redrawProjectsGrid()
{
  //console.log("redrawProjectsGrid");
  var nrofcolumns = 3;
  var columnmargin = 30;
  var normalcolumnwidth = 370;

  var maxwidth3columns = 1170;
  var maxwidth2columns = maxwidth3columns - normalcolumnwidth - columnmargin;
  var maxwidth1column = maxwidth2columns - normalcolumnwidth - columnmargin;

  var projectsgrid = $("#projects");
  var projectswidth = projectsgrid.width() - (0 * columnmargin);
  if (projectswidth < (maxwidth2columns + 0.5 * normalcolumnwidth)) {
    nrofcolumns = 2;
  }
  if (projectswidth < (maxwidth1column + 0.5 * normalcolumnwidth)) {
    nrofcolumns = 1;
  }
  var columnwidth = (projectswidth - ((nrofcolumns-1) * columnmargin) ) / nrofcolumns;
  //var columnwidth = 370;
  //console.log("projectswidth", projectswidth);
  //console.log("nrofcolumns", nrofcolumns);
  //console.log("columnwidth", columnwidth);

  if (nrofcolumns<3) {
    $("html.rotaryhelpt #projects .item").css("max-width", "unset");
  }

  var cnexty = [];
  // set all column height to zero
  var i;
  for (i=0; i<nrofcolumns; i++) {
    cnexty[i]=0;
  }

  var projects = $("#projects .item").not(".hidden");
  //console.log(projects);

  var a=0;
  projects.each( function(_index, _item) {
    a += 1;
    // find the next position, the lowest column this far
    var nextpos = 0;
    for (i=0; i<nrofcolumns; i++) {
      if (cnexty[i]<cnexty[nextpos]) {
        nextpos=i;
      }
    }
    //var itemheight = $(this).height();
    //console.log(_item);
    //$(_item).css("width", "100px");
    $(this).css("position", "absolute");
    //$(this).css("display", "block");
    //$(this).css("left", 3*columnmargin + (nextpos * (columnwidth+columnmargin)) + "px");
    $(this).css("left", nextpos * (columnwidth+columnmargin) + "px");
    $(this).css("top", cnexty[nextpos] + "px");
    $(this).css("width", columnwidth + "px");

//alert("h: " +_item.clientHeight);
    var itemheight = _item.clientHeight;
    //console.log({'itemheight':itemheight});

    cnexty[nextpos] += (itemheight + columnmargin) ;
    //console.log({'itemheight':itemheight, cnexty:cnexty[nextpos]});
  });

  // get maxcolumns
  var projectsgridheight = 0;
  for (i=0; i<nrofcolumns; i++) {
    if (cnexty[i]>projectsgridheight) {
      projectsgridheight = cnexty[i];
    }
  }
  var x2view = $("#projectsfilter .expandfilter a").hasClass("x2");
  //console.log(projectsgridheight);
  if (!x2view) {
    projectsgrid.css("height", columnmargin + projectsgridheight + "px");
  }
  //console.log(projectsgrid.css("height"));
  //console.log($("#projectsfilter .expandfilter a").hasClass("x2"));

  resizetimeout = false;
  if ($("#projects").hasClass("redrawgrid")) {
    //console.log("has class redrawgrid");
    setTimeout(function() {
      $("#projects").removeClass("redrawgrid");
      redrawProjectsGrid();
    }
    , 500);
  }
}

