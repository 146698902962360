import * as dompack from 'dompack';
import $ from 'jquery';

dompack.onDomReady(() => {

  $('.js-toggle-magazine-menu').click(function(_e) {
    _e.preventDefault();
    $('html').toggleClass('magazine__menu-state--open');
  });


  $(window).on('load resize scroll', function() {
    handleMotionElements()
  });

  $(".js-scroll-next-widget").each(function(_element) {
    var $this = $(this);
    var nextwidget = getNextWidgetElement($this);


/*
    var thiswidget = $this.closest(".widget__site");
    var nextwidget = thiswidget.nextAll(".widget__site").get(0);
*/
    if (nextwidget) {
    }
    else{
      $this.addClass("hidden");
    }
  });

  $(document).on("click", ".js-scroll-next-widget", function(_e) {
    _e.preventDefault();
    var $this = $(this);
    var nextwidget = getNextWidgetElement($this);
    if (nextwidget) {
      $('html, body').animate({ scrollTop: getScrollPositionForElement($(nextwidget)) }, 600);
    }
  });

  if (document.documentElement.classList.contains('isinmagazine')) {
    // remove empty paragraphs
    $('.mainpart p.normal').each(function() {
      if ($(this).html() === '<br>') {
        $(this).remove();
      }
    });
  }
});

function getNextWidgetElement(_$element)
{
  var thiswidget = _$element.closest(".widget__site");
  var nextwidget;
  var currentlevel = 0;
  //console.log({ 'currentlevel': currentlevel, 'thiswidget': thiswidget });
  while ((thiswidget !== 'undefined') && (currentlevel<10)) {
    nextwidget = thiswidget.nextAll(".widget__site").get(0);
    if (nextwidget) {
      return nextwidget;
    }
    thiswidget = thiswidget.parent().closest(".widget__site");
    currentlevel = currentlevel + 1;
    //console.log({ 'currentlevel': currentlevel, 'thiswidget': thiswidget });
  }
  return nextwidget;

/*
  var thiswidget = _$element.closest(".widget__site");
  var nextwidget = thiswidget.nextAll(".widget__site").get(0);
  return nextwidget;
*/
}

function getScrollPositionForElement(_$element) {
  if (!_$element || _$element.length === 0) {
    console.error('no element');
    return 0;
  }

  return _$element.get(0).getBoundingClientRect().top;
}

function handleMotionElements()
{
  $('.w-inview.w-motion').each(function() {
    var windowTop = $(window).scrollTop();
    var windowHeight = $(window).height();
    var elementBoundingClientRec = $(this)[0].getBoundingClientRect();
    var elementTop = $(this).offset().top;

    var motionheight = 0;
    var motionpercentage = 0.0;
    if (elementTop<windowHeight) {
      motionheight = elementTop + elementBoundingClientRec.height;
    }
    else {
      motionheight = windowHeight + elementBoundingClientRec.height;
    }
    if (elementBoundingClientRec.bottom>0) {
      motionpercentage = 100 * (motionheight-elementBoundingClientRec.bottom) / motionheight;
    }
    else {
      motionpercentage = 100;
    }
    var newleftpercentage = (motionpercentage * -0.2) + "%";
    //$(this).css({ left: newleftpercentage });
    $(this).css({ '--translateX': newleftpercentage });

    //console.log({'this': $(this), 'motionpercentage': motionpercentage, 'motionheight': motionheight, 'elementbottom': elementBoundingClientRec.bottom });
  });
}
