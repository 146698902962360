
import './counter.scss';

import * as dompack from 'dompack';
import $ from 'jquery';

import { CountUp } from 'countup.js';

dompack.onDomReady(() => {

  $(".widget--counter__counter").each(function(_element) {
    let $this = $(this);
    let thisid = $this.attr("id");

    let endcount = $this.data("counter-endcount");

    let counteroptions = {
      enableScrollSpy: true,
      scrollSpyDelay: 200, /* (msecs) */
      scrollSpyOnce: false,
      useGrouping: false,
    };

    let startcount = $this.data("counter-startcount");
    if (startcount !== undefined) {
      counteroptions.startVal = startcount;
    }

    let duration = $this.data("counter-duration");
    if (duration !== undefined) {
      counteroptions.duration = duration;
    }

    let nrofdecimals = $this.data("counter-nrofdecimals");
    if (nrofdecimals !== undefined) {
      counteroptions.decimalPlaces = nrofdecimals;
      counteroptions.decimal = ",";
    }

    let thousandseparator = $this.data("counter-thousandseparator");
    if (thousandseparator !== undefined) {
      counteroptions.useGrouping = true;
      counteroptions.separator = thousandseparator;
    }

    let prefix = $this.data("counter-textbefore");
    if (prefix !== undefined) {
      counteroptions.prefix = prefix;
    }

    let suffix = $this.data("counter-textafter");
    if (suffix !== undefined) {
      counteroptions.suffix = suffix;
    }

    //console.log({ 'counteroptions': counteroptions, 'id': thisid });

    if (endcount !== undefined) {
      const countUp = new CountUp(thisid, endcount, counteroptions);
    }

  });

});

