import './sidebar.scss';

import * as dompack from 'dompack';
import ClubFinderMap from '../../components/clubfindermap/clubfindermap';

class SidebarClubFinder {
  constructor(node) {
    this.map = new ClubFinderMap(node, { mapTypeControl: false, streetViewControl: false });

    dompack.qS("#clubfinder-sidebar-searchform").addEventListener("submit", evt => { this.handleSearch(evt); });
  }

  async handleSearch(event) {
    dompack.stop(event);
    await this.map.search(dompack.qS("#clubfinder-sidebar-searchinput").value);
  }
}

dompack.register('#clubfinder-sidebar-map', node => new SidebarClubFinder(node));
