import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';
import * as form from '../../shared/js/form';

dompack.onDomReady(() => {

  if ($("html").hasClass("myprofile")) {
    //console.log("on page myprofile");
    form.initFormFields();
    //form.initAddressFields();
    //form.initImageFields();
    $(document).on("click", "div.memberdata.block input.button.edit", function(e) {
      //alert('edit');
      //$(this).closest("div.content").find("div.edit").removeClass("hidden"); //.slideToggle("slow");
      $(this).closest("div.content").find("div.edit").slideToggle("slow");
    });

    $(document).on("click", "div.memberdata.block input.button.save", function(e) {
      e.preventDefault();
      //alert("save");
      var thisform = $(this).closest("form");
      //console.log(thisform.serialize());
      var thisformid = thisform.attr("id");
      //console.log(thisformid);
      var ajaxData = new FormData(document.getElementById(thisformid));
      //console.log(ajaxData);
      page.showLoader();
      $.ajax({
        url: "/data.shtml?action=storeMemberFormData&formname=" + thisformid,
        type: "POST",
        data: ajaxData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        complete: function() {
          //thisform.removeClass('is-uploading');
        },
        success: function(_data) {
          //console.log(_data);
          setTimeout(function() {
            page.hideLoader();
          }, 500);
          if (_data.ok)
          {
            var thisformfeedback = thisform.find("div.formfeedback.error");
            thisformfeedback.addClass("hidden");
            thisform.find(".formfield input.error").removeClass("error");
            $(thisform).find("small.error").addClass("hidden");
            if (_data.item)
            {
              if (_data.item.anyerrors)
              {
                for (var fieldname in _data.item.posterrors) {
                  var field = $("#" + fieldname + "Field");
                  var smallerror = field.next("small.error");
                  if (_data.item.posterrors[fieldname]!="") {
                    field.addClass("error");
                    if (smallerror.length>0) {
                      smallerror.text(_data.item.posterrors[fieldname]);
                      smallerror.removeClass("hidden");
                    }
                    else {
                      field.after('<small class="error">' + _data.item.posterrors[fieldname] + '</small>');
                    }
                  }
                  else {
                    field.removeClass("error");
                    if (smallerror.length>0) {
                      smallerror.addClass("hidden");
                    }
                  }
                }
                thisformfeedback.removeClass("hidden");
               $('html, body').animate({
                  //scrollTop: thisformfeedback.offset().top
                  scrollTop: thisform.offset().top
                }, 1000);
              }
              else
              {
                thisformfeedback.addClass("hidden");
                thisform.find(".formfield input.error").removeClass("error");
                $(thisform).find("small.error").addClass("hidden");
                if ( (_data.item.ischanged) && (_data.item.viewhtml) ) {
                  thisform.find("div.view div.data").html(_data.item.viewhtml);
                }
                if (_data.item.formfeedbackmessage) {
                  thisform.find(".formfeedback.message p strong").html(_data.item.formfeedbackmessage);
                  thisform.find(".formfeedback.message").removeClass("hidden");
                  setTimeout(function() {
                    thisform.find(".formfeedback.message").addClass("hidden");
                  }, 3000);
                }
                if (_data.item.pref_privacy) {
                  thisform.find("legend span.memberprivacy").addClass("locked");
                }
                else {
                  thisform.find("legend span.memberprivacy").removeClass("locked");
                }
                thisform.find("div.content div.edit").slideToggle("slow");
                $('html, body').animate({
                  scrollTop: thisform.offset().top
                }, 1000);
                for (fieldname in _data.item.formfieldchanges) {
                  var field = $("#" + fieldname + "Field");
                  field.val(_data.item.formfieldchanges[fieldname]);
                }
                thisform.find(".divinputfile .cbimagedelete").prop("checked", false);
              }
            }
            else
            {
              thisformfeedback.addClass("hidden");
              thisform.find(".formfield input.error").removeClass("error");
              thisform.find("small.error").addClass("hidden");
              thisform.find(".divinputfile .cbimagedelete").prop("checked", false);
            }
          }
        }
      });
    });



  } // hasClass("myprofile")

});
