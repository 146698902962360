import './clubfinder.scss';

import * as dompack from 'dompack';
import ClubFinderMap from '../../components/clubfindermap/clubfindermap';

class ClubFinder {
  constructor(node) {
    this.map = new ClubFinderMap(node, { resultsContainer: dompack.qS('#clubfinder-results') });

    dompack.qS('#clubfinder-searchform').addEventListener('submit', evt => { this.handleSearch(evt); });
    // this.debugSearch('7521');
  }

  debugSearch(val) {
    dompack.qS('#clubfinder-searchinput').value = val;
    this.map.search(val);
  }

  async handleSearch(event) {
    dompack.stop(event);
    await this.map.search(dompack.qS('#clubfinder-searchinput').value);
  }
}

dompack.register('#clubfinder-map', node => new ClubFinder(node));
