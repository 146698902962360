import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

import "./appbirthdays.scss";

dompack.onDomReady(() => {


});

