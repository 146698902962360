import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../shared/js/page';

import "./appactivityattendances.scss";

dompack.onDomReady(() => {

  //console.log("appactivities js here");

  var qvar = _getQueryVar("ed");
  var actioniconshtml = "";
 
  if ($("html").hasClass("pwa-activityattendances")) {
    if ($("html").hasClass("writeaccess")) {
      if (qvar.length>0) {
        actioniconshtml +=  '<a href="" title="" class="save">opslaan</a>';
      }
      else {
        //actioniconshtml =  '<a href="" title="" class="add"><i class="fas fa-plus"></i></a>';
      }
console.log("attendance actionicons");
    }

    setTimeout(function(){
      // fixme: jannes: actions to be added later
      $(".nav-pwa-header span.right").html(actioniconshtml);
    }, 200);

  }

  $(document).on("click", "html.pwa-activityattendances a.save", function(_e) {
console.log("on save");
    _e.preventDefault();
    $("#formattendance").trigger("submit");
  });

}); // onDomReady

function _getQueryVar(_varname)
{
  var querystring = window.location.search.substring(1);
  var queryvars = querystring.split("&");
  for(var i=0; i<queryvars.length; i++) {
    var queryvar = queryvars[i].split("=");
    if (queryvar[0] == _varname) {
      return queryvar[1];
    }
  }
  return "";
}

function getFormfieldNrOfRecords(_formfield)
{
  return _formfield.find(".records .record").length;
}

