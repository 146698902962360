import $ from 'jquery';

export function showLoader()
{
  //console.log("show loader");
  $("#fullscreenlayer").removeClass("hidden");
}

export function hideLoader()
{
  //console.log("hide loader");
  $("#fullscreenlayer").addClass("hidden");
}
